import { uploader } from "../helpers/uploader";

export default class Documents {
  constructor(el) {
    this.el = el;
    this.individualForm = this.el.querySelector('.js-individual-form');
    this.businessForm = this.el.querySelector('.js-business-form');
    this.switchButtons = this.el.querySelectorAll('.resources__switch__switch-item');

    console.log("individualForm", this.individualForm)
    console.log("businessForm", this.businessForm)
    console.log("switchButtons", this.switchButtons)

    this.initializeForm(this.individualForm);
    this.initializeForm(this.businessForm);

    this.switchButtons.forEach(button => {
      button.addEventListener('click', this.handleSwitch);
    });
  }

  initializeForm(form) {
    if (form) {
      const uploaderEl = form.querySelector('.js-documents-upload');
      const labelEl = form.querySelector('.js-document-filename');
      if (uploaderEl && labelEl) {
        uploader(form, uploaderEl, 'document', () => this.fileNameToLabel(uploaderEl, labelEl));
      }
    }
  }

  handleSwitch = (event) => {
    event.preventDefault();
    const type = event.currentTarget.dataset.type;

    this.switchButtons.forEach(button => {
      button.classList.remove('resources__switch__shift--active');
      button.classList.add('resources__switch__shift');
    });

    event.currentTarget.classList.remove('resources__switch__shift');
    event.currentTarget.classList.add('resources__switch__shift--active');

    this.toggleForm(type);
  }

  toggleForm = (type) => {
    if (type === 'individuals') {
      this.individualForm.style.display = 'block';
      this.businessForm.style.display = 'none';
    } else {
      this.individualForm.style.display = 'none';
      this.businessForm.style.display = 'block';
    }
  }

  fileNameToLabel = (uploaderEl, labelEl) => {
    if (uploaderEl.files.length > 0) {
      labelEl.textContent = uploaderEl.files[0].name;
    }
  }
}
